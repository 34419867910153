import React, { useEffect, useState, useContext } from "react";
import { Breadcrumb } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { StateContext } from "./state";

const BreadcrumbBar = (props: any) => {
  const [{ carrierProduct }] = useContext(StateContext);
  const homeCrumb = { path: carrierProduct.pathPrefix, title: "Year & Index" };
  const [breadcrumbNavState, setBreadcrumbNavState] = useState({
    nav: [homeCrumb]
  });

  const breadcrumbLookup = (currentPath: string) => {
    switch (currentPath) {
      case carrierProduct.pathPrefix:
        return "Year & Index";
      case "index-birth-year":
        return {
          path: carrierProduct.pathPrefix + "index-birth-year",
          title: "Index Chart"
        };
      case "value":
        return {
          path: carrierProduct.pathPrefix + "index-birth-year/value",
          title: "Value Chart"
        };
      case "calculations":
        return {
          path:
            carrierProduct.pathPrefix + "index-birth-year/value/calculations",
          title: "Calculation Table"
        };
      default:
        return { path: "", title: "" };
    }
  };

  const generateBreadcrumbs = () => {
    const pathParts = props.location.pathname.split("/");

    let updatedBreadcrumbNav: Array<any> = [];
    pathParts.forEach((part: any) => {
      const crumbInfo = breadcrumbLookup(part);

      if (part !== "" && part !== "201" && part !== "202") {
        updatedBreadcrumbNav.push(crumbInfo);
      }
    });

    // Utility fn to check for path in array
    const checkIfPathContains = (array: any, val: any) => {
      return array.some((arrayVal: any) => val === arrayVal.path);
    };

    // Check for empty paths, if an empty path then strip all breadcrumbs
    if (checkIfPathContains(updatedBreadcrumbNav, "")) {
      updatedBreadcrumbNav = [];
    }
    // Check if the path is starting to build, if so add home crumb
    else if (
      checkIfPathContains(
        updatedBreadcrumbNav,
        carrierProduct.pathPrefix + "index-birth-year"
      )
    ) {
      updatedBreadcrumbNav.unshift(homeCrumb);
    }

    setBreadcrumbNavState({ nav: updatedBreadcrumbNav });
  };

  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;

    generateBreadcrumbs();

    return () => {
      isMounted.current = false;
    };
  }, [props.location]);

  let content = [];
  for (let i = 0; i < breadcrumbNavState.nav.length; i++) {
    if (i + 1 === breadcrumbNavState.nav.length) {
      content.push(
        <Breadcrumb.Section
          key={breadcrumbNavState.nav[i].path}
          as={NavLink}
          exact
          to={{
            ...props.location,
            pathname: breadcrumbNavState.nav[i].path
          }}
        >
          {breadcrumbNavState.nav[i].title}
        </Breadcrumb.Section>
      );
    } else {
      let toPath = {
        ...props.location,
        pathname: breadcrumbNavState.nav[i].path
      };

      // if this crumb is home, remove search or will throw error b/c of state change
      if (breadcrumbNavState.nav[i].path === carrierProduct.pathPrefix) {
        toPath = {
          ...props.location,
          search: "",
          pathname: breadcrumbNavState.nav[i].path
        };
      }
      content.push(
        <React.Fragment key={breadcrumbNavState.nav[i].path}>
          <Breadcrumb.Section as={NavLink} exact to={toPath}>
            {breadcrumbNavState.nav[i].title}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
        </React.Fragment>
      );
    }
  }

  return <Breadcrumb size="large">{content}</Breadcrumb>;
};

export default BreadcrumbBar;
