// State management with React Hooks
//https://medium.com/simply/state-management-with-react-hooks-and-context-api-at-10-lines-of-code-baf6be8302c

import React, { createContext, useContext, useReducer } from "react";

// interface IActions {
//   type: string;
//   value: any;
// }

// interface IStateProviderProps {
//   reducer: Reducer<any, IActions>;
//   initialState: Dispatch<IActions>;
// }

// interface IInitContextProps {
//   state: any; //IStateProviderProps;
//   dispatch: Dispatch<IActions>;
// }

export const StateContext = createContext({} as any);
export const StateProvider: React.FC<any> = ({
  reducer,
  initialState,
  children,
}) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);
