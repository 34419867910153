import React, { useState, useContext } from "react";
import {
  Segment,
  Responsive,
  Form,
  Input,
  Message,
  MessageContent,
  Button
} from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";
import { StateContext } from "../state";

import httpClient from "./api/HTTPClient";
import Endpoint from "./api/Endpoint";

interface IError {
  content: string;
  pointing: string;
}

const Forgot = () => {
  const [state, setState] = useState<{
    error?: IError;
    id: string;
    isBusy: boolean;
  }>({
    id: "",
    isBusy: false
  });
  const [{ carrierProduct }] = useContext(StateContext);
  const history = useHistory();

  const { error, id, isBusy } = state;
  const apiError = error && error.pointing === "api" ? error : undefined;

  const handleSubmit = async () => {
    if (!id || !id.trim().length) {
      setState({
        ...state,
        error: {
          content: "Please enter the email id",
          pointing: "below"
        }
      });
      return;
    }
    try {
      setState({ ...state, isBusy: true });
      const { data } = await httpClient.post(Endpoint.Forgot, {
        username: id
      });
      if (!data.success) {
        throw new Error(
          data.message ||
            "An error occurred while recovering your account, please make sure the email is correct."
        );
      }
      history.push(carrierProduct.pathPrefix + "auth/recover", {
        message: `We have sent an email to ${data.result.CodeDeliveryDetails.Destination} with the recovery code for changing the password.`
      });
    } catch (e) {
      setState({
        ...state,
        isBusy: false,
        error: {
          content: typeof e === "string" ? e : e.message,
          pointing: "api"
        }
      });
    }
  };

  return (
    <>
      {carrierProduct && (
        <div style={{ maxWidth: 500, margin: "16px auto" }}>
          <Segment.Group size="massive" raised>
            <Responsive as={Segment}>
              <Form onSubmit={handleSubmit}>
                <Message warning={!!apiError}>
                  <MessageContent>
                    {apiError
                      ? apiError.content
                      : "Enter your email address and we will send an email with instructions to recover your account."}
                  </MessageContent>
                </Message>

                <Form.Field
                  control={Input}
                  label="User ID"
                  error={error && error.pointing !== "api" ? error : undefined}
                  type="email"
                  icon="user"
                  iconPosition="left"
                  placeholder="User ID"
                  onChange={(event: any) =>
                    setState({
                      error: undefined,
                      isBusy: false,
                      id: event.target.value
                    })
                  }
                />
                <Button
                  disabled={isBusy}
                  loading={isBusy}
                  primary
                  fluid
                  type="submit"
                >
                  Recover password
                </Button>
                <p>
                  <Link to={carrierProduct.pathPrefix + "auth/login"}>
                    Login
                  </Link>
                </p>
              </Form>
            </Responsive>
          </Segment.Group>
        </div>
      )}
    </>
  );
};

export default Forgot;
