import React, { useState, useContext } from "react";
import { RouteComponentProps, Link, useHistory } from "react-router-dom";
import {
  Segment,
  Responsive,
  Form,
  MessageContent,
  Message,
  Button,
  Input
} from "semantic-ui-react";
import httpClient from "./api/HTTPClient";
import Endpoint from "./api/Endpoint";
import { StateContext } from "../state";

type RecoverFormFields = "password" | "id" | "code" | "verify-password";
interface RecoverFormState {
  message: string;
  isBusy: boolean;
  error?: {
    field: RecoverFormFields | "api";
    content: string;
    pointing: string;
  };
  code: string;
  email: string;
  password: string;
  verifyPassword: string;
}

const validateRecoverform = (
  state: RecoverFormState
):
  | {
      content: string;
      field: RecoverFormFields;
    }
  | undefined => {
  const { code, email, verifyPassword, password } = state;
  if (!email || !email.trim().length) {
    return {
      field: "id",
      content: "Please enter your email address."
    };
  }
  if (!code || !code.trim().length) {
    return {
      field: "code",
      content: "Please enter the code that was sent to your email."
    };
  }
  if (!password || password.trim().length < 6) {
    return {
      field: "password",
      content: "Please enter a password that is at least six characters long."
    };
  }
  if (password !== verifyPassword) {
    return {
      field: "verify-password",
      content: "Both password must be the same."
    };
  }
};

const Password = ({
  location: { state: passedState }
}: RouteComponentProps<
  {},
  {},
  {
    message: string;
  }
>) => {
  const [state, setState] = useState<RecoverFormState>({
    message: passedState ? passedState.message : "",
    isBusy: false,
    code: "",
    email: "",
    password: "",
    verifyPassword: ""
  });
  const [{ carrierProduct }] = useContext(StateContext);
  const history = useHistory();
  const { message, isBusy, error } = state;
  const handleSubmit = async () => {
    const { code, email, password } = state;
    const validation = validateRecoverform(state);
    if (validation !== undefined) {
      setState({ ...state, error: { ...validation, pointing: "below" } });
      return;
    }
    try {
      setState({ ...state, isBusy: true });
      const { data } = await httpClient.post(Endpoint.Recover, {
        username: email,
        code,
        password
      });
      if (!data.success) {
        throw new Error(
          data.message || "There was an error changing the password."
        );
      }
      history.push(carrierProduct.pathPrefix + "auth/login", {
        message:
          "You have successfully changed the password. You may now login."
      });
    } catch (error) {
      setState({
        ...state,
        isBusy: false,
        message: typeof error === "string" ? error : error.message,
        error: {
          content: "",
          field: "api",
          pointing: ""
        }
      });
    }
  };

  return (
    <>
      {carrierProduct && (
        <div style={{ maxWidth: 500, margin: "16px auto" }}>
          <Segment.Group size="massive" raised>
            <Responsive as={Segment}>
              <Form onSubmit={handleSubmit}>
                {!!message && (
                  <Message negative={error && error.field === "api"}>
                    <MessageContent>{message}</MessageContent>
                  </Message>
                )}
                <Form.Field
                  control={Input}
                  label="User ID"
                  error={error && error.field === "id" ? error : undefined}
                  type="email"
                  icon="user"
                  iconPosition="left"
                  placeholder="User ID"
                  onChange={(event: any) =>
                    setState({
                      ...state,
                      email: event.target.value,
                      isBusy: false
                    })
                  }
                />
                <Form.Field
                  control={Input}
                  label="Verification code"
                  error={error && error.field === "code" ? error : undefined}
                  type="text"
                  icon="keyboard"
                  iconPosition="left"
                  placeholder="Verification code"
                  onChange={(event: any) =>
                    setState({
                      ...state,
                      code: event.target.value,
                      isBusy: false
                    })
                  }
                />
                <Form.Field
                  control={Input}
                  label="Password"
                  error={
                    error && error.field === "password" ? error : undefined
                  }
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  onChange={(event: any) =>
                    setState({
                      ...state,
                      password: event.target.value,
                      isBusy: false
                    })
                  }
                />
                <Form.Field
                  control={Input}
                  label="Repeat password"
                  error={
                    error && error.field === "verify-password"
                      ? error
                      : undefined
                  }
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Repeat password"
                  onChange={(event: any) =>
                    setState({
                      ...state,
                      verifyPassword: event.target.value,
                      isBusy: false
                    })
                  }
                />
                <Button
                  disabled={isBusy}
                  loading={isBusy}
                  primary
                  fluid
                  type="submit"
                >
                  Change password
                </Button>
                <p>
                  <Link to={carrierProduct.pathPrefix + "auth/login"}>
                    Login
                  </Link>
                </p>
              </Form>
            </Responsive>
          </Segment.Group>
        </div>
      )}
    </>
  );
};

export default Password;
