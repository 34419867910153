import React, { useState } from "react";

import { Accordion, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

const BenefitFaq = () => {
  const [accActiveIndex, setAccActiveIndex] = useState(0);

  const handleAccordion = (e: any, props: any) => {
    const { index } = props;
    const newIndex = accActiveIndex === index ? -1 : index;
    setAccActiveIndex(newIndex);
  };

  return (
    <>
      <Accordion fluid styled>
        <Accordion.Title
          active={accActiveIndex === 0}
          index={0}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          Is the Social Security Benefit Index provided by the Social Security
          Administration?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 0}>
          <p>
            The Social Security Benefit Index is not provided by or in any way
            affiliated with the Social Security Administration. The Social
            Security Index uses calculation details made publicly available by
            the Social Security Administration. The Social Security
            Administration does not in any way endorse or guarantee the validity
            of the Social Security Benefit Index.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={accActiveIndex === 1}
          index={1}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          What is the purpose of the Social Security Benefit Index?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 1}>
          <p>
            The Social Security Benefit Index is intended for use with a variety
            of insurance and financial products to measure changes in the way
            Social Security benefits might be calculated in the future. The
            issuers of these products have licensed the right to reference and
            use the Social Security Benefit Index. Use of the Social Security
            Benefit Index is limited as described by the Social Security Benefit
            Index Terms of Use that you accepted as a condition of entering this
            website.{" "}
            <Link target="_blank" to="/accept-terms">
              Click here to see this again
            </Link>
            .
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={accActiveIndex === 2}
          index={2}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          How are the hypothetical Social Security benefits calculated?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 2}>
          <p>
            The calculations used in the Social Security Benefit Index follow
            the methodology for calculation of benefits made publicly available
            by the Social Security Administration. To provide complete
            transparency, the details behind the calculations are shown on this
            website.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={accActiveIndex === 3}
          index={3}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          How were the Salary Histories created?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 3}>
          <p>
            The salary histories used in the Social Security Benefit Index were
            created to be representative of a salary history for an individual
            that started work at age 22 for a particular birth year. Three
            income levels (Middle, Upper-Middle and Upper Income) were used
            because Social Security benefit calculations treat income levels
            differently. The salary histories are meant to be purely
            hypothetical and are not intended to match any individual’s salary
            history.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={accActiveIndex === 4}
          index={4}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          How were the Social Security Start Ages determined?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 4}>
          <p>
            The Social Security Start Ages span the range of possible ages from
            earliest start age to full retirement age to latest start age. A
            range of ages was used because the Social Security Administration
            calculates benefits differently for different Social Security Start
            Ages.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={accActiveIndex === 5}
          index={5}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          How were the weights that apply to the individual values to calculate
          the Index Value determined?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 5}>
          <p>
            In calculating the Index Value, the weights applied to the
            individual values are representative of the demographic for the
            specific use of the index.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={accActiveIndex === 6}
          index={6}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          How would the Social Security Benefit Index be calculated if there
          were a significant change to the methodology used to calculate
          benefits?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 6}>
          <p>
            The Social Security Benefit Index has an Index Committee that helps
            ensure the index is being reasonably calculated and is accomplishing
            its intended purpose. The Index Committee is responsible for
            determining what should be done if there were a fundamental change
            to Social Security. The Index Committee is also responsible for
            determining how to back-fill the components of the index if an
            additional parameter became necessary to perform the benefit
            calculation. The Index Committee would work with any applicable
            regulators to demonstrate the reasonability of the change to the
            Social Security Benefit Index calculation.
          </p>
        </Accordion.Content>

        <Accordion.Title
          active={accActiveIndex === 7}
          index={7}
          onClick={handleAccordion}
        >
          <Icon name="dropdown" />
          Who do I contact if I have a question about the Social Security
          Benefit Index?
        </Accordion.Title>
        <Accordion.Content active={accActiveIndex === 7}>
          <p>
            Please email questions to:{" "}
            <a href="mailto:questions@socialsecuritybenefitindex.org?subject=Benefit%20Index%20Question">
              questions@socialsecuritybenefitindex.org
            </a>
          </p>
        </Accordion.Content>
      </Accordion>
    </>
  );
};

export default BenefitFaq;
