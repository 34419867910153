import React from "react";
import { Header, Popup, Icon } from "semantic-ui-react";

const popperContent = (modalType: string) => {
  switch (modalType) {
    case "benefit base":
      return {
        title: "Contribution and Benefit Base",
        description: (
          <>
            <p>
              The Social Security's Old-Age, Survivors, and Disability Insurance
              (OASDI) program limits the amount of earnings subject to taxation
              for a given year.
            </p>
            <p>
              The same annual limit also applies when those earnings are used
              ina benefit computation. This limit changes each year with changes
              in the national average wage index. This annual limit is called
              the Contribution and Benefit Base. This amount is also commonly
              referred to as the taxable maximum.
            </p>
            <p>
              More information can be found at{" "}
              <a
                href="https://www.ssa.gov/OACT/COLA/cbb.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ssa.gov/OACT/COLA/cbb.html
              </a>
            </p>
          </>
        ),
      };
    case "national average":
      return {
        title: "National Average Wage Index",
        description: (
          <>
            <p>
              When computing a person's retirement benefit, the SSA uses the
              national average wage indexing series to index that person's
              earnings. Such indexation ensures that a worker's future benefits
              reflect the general rise in the standard of living that occurred
              during his or her working lifetime.
            </p>
            <p>
              More information can be found at{" "}
              <a
                href="https://www.ssa.gov/OACT/COLA/AWI.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ssa.gov/OACT/COLA/AWI.html
              </a>
            </p>
          </>
        ),
      };
    case "aime":
      return {
        title: "Average Indexed Monthly Earnings (AIME)",
        description: (
          <>
            <p>
              The Average Indexed Monthly Earnings (AIME) is calculated by
              summing the highest 35 Indexed Earnings value and dividing by 420
              to get the monthly average indexed earnings. This value is rounded
              down to the nearest dollar.
            </p>
            <p>
              More information can be found on the Average Indexed Monthly
              Earnings and the Primary Insurance Amount at{" "}
              <a
                href="https://www.ssa.gov/OACT/COLA/Benefits.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ssa.gov/OACT/COLA/Benefits.html
              </a>
            </p>
          </>
        ),
      };
    case "pia":
      return {
        title: "Primary Insurance Amount (PIA)",
        description: (
          <>
            <p>
              The Primary Insurance Amount (PIA) is determined using the
              prescribed Bend Points. These Bend Points are used to calculate
              the PIA by applying different multiples for different ranges of
              the AIME. Bend points have historically been different and could
              change.
            </p>
            <p>
              More information can be found on the Average Indexed Monthly
              Earnings and the Primary Insurance Amount at{" "}
              <a
                href="https://www.ssa.gov/OACT/COLA/Benefits.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ssa.gov/OACT/COLA/Benefits.html
              </a>
            </p>
          </>
        ),
      };
    case "early factor":
      return {
        title: "Early/Delayed Benefit Factor",
        description: (
          <>
            <p>
              The Early/Delayed Benefit Factor adjusts the PIA, after COLA, to
              determine the Monthly Benefit Amount (MBA). These factors reduce
              the MBA if benefits are taken before full retirement age and
              increase benefits when taken after.
            </p>
            <p>
              Details on Early/Delayed Benefit Factors can be found at{" "}
              <a
                href="https://www.ssa.gov/OACT/ProgData/ar_drc.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ssa.gov/OACT/ProgData/ar_drc.html
              </a>
            </p>
          </>
        ),
      };
    case "hypothetical earnings":
      return {
        title: "Hypothetical Earnings",
        description: (
          <p>
            The index uses hypothetical earnings histories for each of the
            hypothetical individuals that make-up the index. These earnings
            histories were created to be representative of the particular
            hypothetical individual’s demographic and inflation adjusted
            historic earnings.
          </p>
        ),
      };
    case "cola":
      return {
        title: "COLA Adjustments",
        description: (
          <>
            <p>
              The Calculation of the Primary Insurance Amount (PIA) always
              assumes payments start at age 62. If payments are starting later,
              the PIA is adjusted based on the COLA.
            </p>
            <p>
              The history of Cost of Living Adjustments can be found at{" "}
              <a
                href="https://www.ssa.gov/OACT/COLA/colaseries.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ssa.gov/OACT/COLA/colaseries.html
              </a>
            </p>
          </>
        ),
      };

    case "highest earnings":
      return {
        title: "35 Highest Indexed Earnings",
        description: (
          <>
            <p>TABLE OF 35 Highest Indexed Earnings DATA FORTHCOMING</p>
          </>
        ),
      };

    default:
      return {
        title: null,
        description: "Error: Unknown Path",
      };
  }
};

const CalcTablePopper = (props: any) => {
  return (
    <>
      <Popup
        wide="very"
        inverted
        header={
          <Header size="small">{popperContent(props.content).title}</Header>
        }
        content={popperContent(props.content).description}
        on="click"
        pinned
        trigger={
          <Icon
            color="blue"
            circular
            link
            name="question"
            style={{ fontSize: "0.75rem", marginLeft: "0.25rem" }}
          />
        }
      />
    </>
  );
};

export default CalcTablePopper;
