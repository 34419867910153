import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { LOCAL_USER_KEY } from '../Login';
import Endpoint from './Endpoint';

const BASE_URL: string = 'https://0dko4445bf.execute-api.us-east-1.amazonaws.com/dev';

const httpClient = axios.create({
  baseURL: BASE_URL,
});

// The routes in which we don't need to send the auth token
const excludedRoutes = [Endpoint.Login, Endpoint.Register, Endpoint.Verify, Endpoint.Forgot, Endpoint.Recover]

httpClient.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    const path = request.url ? request.url.replace(BASE_URL, '') : '';
    if (!excludedRoutes.includes(path)) {
      const user = JSON.parse(localStorage.getItem(LOCAL_USER_KEY) || '{}');
      if (user.IdToken) {
        request.headers['Authorization'] = `Bearer: ${user.IdToken}`;
      }
    }
    return request;
  },
  error => Promise.reject(error.message)
);

httpClient.interceptors.response.use(
  (response: AxiosResponse) => response,
  error => Promise.reject(error.response.data)
)

export default httpClient;
