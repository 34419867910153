import React, { useState, useEffect } from "react";

import {
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";

const helper = require("./helpers");

const yAxisTickFormatter = (value: any) => helper.CurrencyFormatter(value, 0);

const convertData = (data: any) => {
  let newData: Array<any> = [];
  Object.values(data).forEach((value: any, index: number) => {
    let obj = {
      value: null,
      income: null,
      weight: "",
      ss_start: null,
      age_segment: "",
      barIndex: 0,
    };
    obj.value = value.value;
    obj.income = value.income;
    obj.weight = value.weight;
    obj.ss_start = value.ss_start;
    obj.age_segment = `${value.income}, ${value.weight}`;
    obj.barIndex = index;
    newData.push(obj);
  });
  return newData;
};

const CustomizedTooltip = (props: any) => {
  if (props.payload && props.active) {
    const { payload } = props;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "rgba(255,255,255,0.9",
          padding: "5px 8px",
        }}
      >
        <div style={{ marginBottom: "3px" }}>
          Value:{" "}
          {helper.CurrencyFormatter(
            payload.length > 0 ? payload[0].payload.value : "N/A",
            2
          )}
        </div>
        <div style={{ marginBottom: "3px" }}>
          Income: {payload.length > 0 ? payload[0].payload.income : "N/A"}
        </div>
        <div style={{ marginBottom: "3px" }}>
          Social Security Start Age:{" "}
          {payload.length > 0 ? payload[0].payload.ss_start : "N/A"}
        </div>
        <div style={{ marginBottom: "3px" }}>
          Weight in Average:{" "}
          {payload.length > 0 ? payload[0].payload.weight : "N/A"}
        </div>
      </div>
    );
  }
  return <div />;
};

const ValueBarChart = (props: any) => {
  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    setChartData(convertData(props.chartData));
  }, [props.chartData]);

  return (
    <>
      <ResponsiveContainer width="99%">
        <BarChart
          data={chartData}
          margin={{ top: 0, right: 0, left: 0, bottom: 75 }}
        >
          <defs>
            <linearGradient id="colorIndexBar" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#C0DCB1" stopOpacity={0.7} />
              <stop offset="95%" stopColor="#095386" stopOpacity={0.7} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="age_segment"
            interval={0}
            angle={-45}
            textAnchor="end"
          />
          <YAxis tickFormatter={yAxisTickFormatter} />
          <Tooltip
            cursor={{ fill: "#000000", fillOpacity: 0.05 }}
            content={<CustomizedTooltip />}
          />
          {/* <Bar dataKey="Year" fill="#C0DCB1" /> */}
          <Bar
            stroke="transparent"
            dataKey="value"
            fill="url(#colorIndexBar)"
            fillOpacity={0.7}
            // onClick={handleBarClick}
            onClick={(e) => props.handleBarClick(e)}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default ValueBarChart;
