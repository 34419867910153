import React, { useContext } from "react";
import { Link, withRouter } from "react-router-dom";
import { Grid, Header, Message, Responsive } from "semantic-ui-react";
import { StateContext } from "./state";
import { useQueryParams, StringParam } from "use-query-params";

const AppHeader = (props: any) => {
  const [queryParams] = useQueryParams({
    yob: StringParam,
    indexYear: StringParam,
    yobIndex: StringParam
  });

  const [{ user, yearSpan }] = useContext(StateContext);
  const isLoggedIn = (user && user.userId) !== undefined;

  const headerInfoText = (testPath: string) => {
    let currentPath = testPath;

    let pathWithoutSlash;
    if (
      props.carrierPathPrefix &&
      props.carrierPathPrefix.length === 5 &&
      props.carrierPathPrefix.endsWith("/")
    ) {
      pathWithoutSlash = props.carrierPathPrefix.substring(
        0,
        props.carrierPathPrefix.length - 1
      );
    }

    switch (currentPath) {
      case props.carrierPathPrefix:
      case pathWithoutSlash:
      case props.carrierPathPrefix + "auth/login":
      case props.carrierPathPrefix + "auth/register":
      case props.carrierPathPrefix + "auth/verify":
      case props.carrierPathPrefix + "auth/forgot":
      case props.carrierPathPrefix + "auth/recover":
        if (isLoggedIn) {
          return {
            component: (
              <Message info>
                The Social Security Benefit Index is based on hypothetical
                lives. The values shown here are not intended to equal yours or
                anyone elses benefit.
              </Message>
            )
          };
        } else {
          return {
            component: (
              <Message info>
                Please register or login to access the The Social Security
                Benefit Index.
              </Message>
            )
          };
        }
      case props.carrierPathPrefix + "index-birth-year":
        return {
          component: (
            <Message>
              <p>
                {`The indexed benefit between the years ${
                  yearSpan ? yearSpan.start : ""
                } and ${yearSpan ? yearSpan.end : ""}. Click a bar
              on the chart for a given year to view the value.`}
              </p>
            </Message>
          )
        };
      case props.carrierPathPrefix + "index-birth-year/value":
        return {
          component: (
            <Message>
              <p>
                {`The indexed value of the benefit for ${queryParams.yob}.
              Click a bar on the chart to view a table of the calculations.`}
              </p>
            </Message>
          )
        };
      case props.carrierPathPrefix + "index-birth-year/value/calculations":
        return {
          component: (
            <Message>
              <p>
                {`The calculated values for Birth Year ${queryParams.yob}
              with Earnings, Contribution/Benefit Base, and Averaged Indexed
              Earnings for index year ${queryParams.indexYear}.`}
              </p>
            </Message>
          )
        };
      default:
        return {
          component: null,
          info: "Error: Unknown Path"
        };
    }
  };

  return (
    <Grid verticalAlign="middle" columns={2} style={{ marginBottom: "1em" }}>
      <Grid.Row>
        <Grid.Column
          mobile={16}
          tablet={5}
          computer={5}
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <Responsive as="div">
            <Header as="h1" className="app-header">
              <Link
                to={props.carrierPathPrefix ? props.carrierPathPrefix : "/"}
              >
                Social Security
                <div className="app-header-big">Benefit Index</div>
              </Link>
            </Header>
          </Responsive>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={11} computer={11}>
          <div className="app-header__message">
            {headerInfoText(props.location.pathname).component}
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default withRouter(AppHeader);
