import React from "react";
import { Button, Modal } from "semantic-ui-react";
import TermsOfUse from "./TermsOfUse";

const EntryModal = (props: any) => {

  return (
    <>
      <Modal
        dimmer="blurring"
        open={props.show}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Accept Terms and Conditions</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <TermsOfUse />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            icon="checkmark"
            labelPosition="right"
            content="Accept"
            onClick={props.handleModalClose}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default EntryModal;
