import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const trackingId = "UA-131266182-6";

const usePageTracking = (isLoggedIn = false) => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {

    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(trackingId);
      setInitialized(true);
    }
    else {
      console.log("!!! GA Page Tracking is off in localhost. See usePageTracking component !!!")
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      const page = location.pathname + location.search;
      let pageView = page;

      if(page === "/") {
        if(isLoggedIn) {
          pageView = "/year-index-entry"
        }
        else {
          pageView = "/auth/login"
        }
      }

      ReactGA.pageview(pageView);
    }
  }, [initialized, location, isLoggedIn]);

  return {
    initialized
  };
};

export default usePageTracking;