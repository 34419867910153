import React, { useState, useContext, useEffect } from "react";
import { Grid, Header, Loader, Responsive, Segment } from "semantic-ui-react";
import { withRouter, useLocation, Redirect } from "react-router-dom";

import useAxios, { configure } from "axios-hooks";
import { useQueryParams, StringParam } from "use-query-params";

import ValueBarChart from "../ValueBarChart";
import httpClient from "../auth/api/HTTPClient";

import { StateContext } from "../state";

configure({ axios: httpClient });

const ValuesView = (props: any) => {
  const location = useLocation();
  const [{ carrierProduct, currentYear }] = useContext(StateContext);
  const [queryParams, setQueryParams] = useQueryParams({
    yob: StringParam,
    indexOption: StringParam,
    valueIndex: StringParam,
    valueIncome: StringParam,
    valueSsStart: StringParam,
    yobIndex: StringParam,
    indexYear: StringParam,
  });

  const [goToCalcTableRoute, setGoToCalcTableRoute] = useState(false);

  const [redirectHome, setRedirectHome] = useState(false);

  const [valueChartData, setValueChartData] = useState();

  const pathToFile: string = `/index/get_summary?yob=${queryParams.yob}&data_year=${currentYear}`;
  const [{ data, loading, error }] = useAxios(pathToFile);

  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;

    try {
      const yobIndex = queryParams.yobIndex ? queryParams.yobIndex : "";

      if (data && yobIndex) {
        const individualData = data.data[yobIndex].individual;
        if (Object.keys(individualData).length > 0) {
          setValueChartData(individualData);
        }
      }
    } catch (err) {
      console.log("setValueChartData ERROR ", err);
      setRedirectHome(true);
    }

    return () => {
      isMounted.current = false;
    };
  }, [data, queryParams.yobIndex]);

  const handleBarAction = (barChartEventData: any) => {
    // If bar in chart is clicked, and there is a payload,
    // call fn in parent to add item to breadcrumb list
    if (barChartEventData) {
      // Add year of birth index to query string
      setQueryParams({
        valueIndex: barChartEventData.payload.barIndex + 1,
        valueIncome: barChartEventData.payload.income,
        valueSsStart: barChartEventData.payload.ss_start,
      });

      // Route to Calc Table with <Redirect />
      setGoToCalcTableRoute(true);
    }
  };

  // Responsive
  const [viewWidth, setViewWidth] = useState(100);
  const indexTextAlign =
    viewWidth >= Responsive.onlyComputer.minWidth! ? "right" : "left";
  const chartHeight =
    viewWidth >= Responsive.onlyComputer.minWidth! ? "500px" : "300px";
  const lgThanTablet =
    viewWidth >= Responsive.onlyTablet.minWidth! ? true : false;
  const handleOnUpdate = (e: any, viewWidth: any) => {
    setViewWidth(viewWidth.width);
  };

  if (error || redirectHome || !queryParams.yobIndex) {
    return <Redirect to={carrierProduct.pathPrefix} exact={true} />;
  }
  if (goToCalcTableRoute) {
    return (
      <Redirect
        to={{
          ...location,
          pathname:
            carrierProduct.pathPrefix + "index-birth-year/value/calculations",
          state: {
            carrierPathId: carrierProduct.carrierPathId,
            carrierPathPrefix: carrierProduct.pathPrefix,
          },
        }}
      />
    );
  }
  return (
    <section className="bi-m-section-top">
      {loading && <Loader size="big" active />}
      {!loading && data && (
        <Responsive
          as={Segment}
          fireOnMount
          onUpdate={handleOnUpdate}
          raised
          {...(lgThanTablet && { padded: true })}
        >
          <Header as="h2" size={lgThanTablet ? "large" : "medium"}>
            <Grid columns={2}>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                style={{ paddingBottom: 0 }}
              >
                Value for Index Year{" "}
                {queryParams.indexYear ? queryParams.indexYear : ""}
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                textAlign={indexTextAlign}
              >
                <Header sub>
                  <em>
                    {queryParams.indexOption ? queryParams.indexOption : ""}
                  </em>
                </Header>
              </Grid.Column>
            </Grid>
          </Header>
          <div style={{ marginTop: "3rem", height: chartHeight }}>
            {valueChartData && (
              <ValueBarChart
                chartData={valueChartData}
                handleBarClick={handleBarAction}
              />
            )}
          </div>
        </Responsive>
      )}
    </section>
  );
};

export default withRouter(ValuesView);
