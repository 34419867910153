import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Container, Responsive } from "semantic-ui-react";

import AppHeader from "../AppHeader";
import Login from "./Login";
import Register from "./Register";
import Verify from "./Verify";
import Recover from "./Recover";
import Forgot from "./Forgot";

const AuthScreen = () => {
  let location = useLocation();

  // Responsive
  const [viewWidth, setViewWidth] = useState(0);
  const [appPadding, setAppPadding] = useState({ padding: "0" });

  useEffect(() => {
    if (viewWidth < Responsive.onlyTablet.minWidth!) {
      setAppPadding({ padding: "1rem 0 0" });
    } else if (viewWidth > Responsive.onlyTablet.minWidth!) {
      setAppPadding({ padding: "3rem" });
    }
  }, [viewWidth]);

  const handleOnUpdate = (e: any, viewWidth: any) => {
    setViewWidth(viewWidth.width);
  };

  let carrierPathId = location.pathname.substr(1, 3);

  if (carrierPathId !== "201" && carrierPathId !== "202") {
    carrierPathId = "";
  }

  let pathPrefix = carrierPathId ? "/" + carrierPathId + "/" : "/";

  return (
    <>
      <Responsive
        as="div"
        fireOnMount
        className="app"
        onUpdate={handleOnUpdate}
        style={appPadding}
      >
        <Container className="app-container">
          <div className="app-inner">
            <AppHeader />
            <div style={{ marginTop: "5rem" }}>
              <Switch>
                <Route
                  path={pathPrefix}
                  state={{ carrierPathPrefix: pathPrefix }}
                  exact={true}
                  component={Login}
                />
                <Route
                  path={pathPrefix + "auth/login"}
                  state={{ carrierPathPrefix: pathPrefix }}
                  exact={true}
                  component={Login}
                />
                <Route
                  path={pathPrefix + "auth/register"}
                  exact={true}
                  component={Register}
                />
                <Route
                  path={pathPrefix + "auth/verify"}
                  exact={true}
                  component={Verify}
                />
                <Route
                  path={pathPrefix + "auth/forgot"}
                  exact={true}
                  component={Forgot}
                />
                <Route
                  path={pathPrefix + "auth/recover"}
                  exact={true}
                  component={Recover}
                />
                <Redirect path="*" to={pathPrefix} />
              </Switch>
            </div>
          </div>
        </Container>
      </Responsive>
    </>
  );
};

export default AuthScreen;
