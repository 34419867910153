import React from "react";
import { Button, Modal, Table } from "semantic-ui-react";

const helper = require("./helpers");

const Top35EarningsModal = (props: any) => {
  return (
    <>
      <Modal
        dimmer="blurring"
        open={props.isShowing}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={props.handleModalClose}
      >
        <Modal.Header>Top 35 Highest Indexed Earnings</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Table compact celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Number</Table.HeaderCell>
                  <Table.HeaderCell>Indexed Earnings</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Object.keys(props.modalData).length === 0 &&
                props.modalData.constructor === Object ? (
                  <Table.Row>
                    <Table.Cell>
                      Top 35 Indexed Earnings not applicable.
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  Object.entries(props.modalData).map(
                    (item: any, index: any) => {
                      return (
                        <Table.Row key={index}>
                          <Table.Cell>{parseInt(item[0]) + 1}</Table.Cell>
                          <Table.Cell>
                            {item[1] >= 0
                              ? helper.CurrencyFormatter(item[1], 2)
                              : ""}
                          </Table.Cell>
                        </Table.Row>
                      );
                    }
                  )
                )}
              </Table.Body>
            </Table>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Close" onClick={props.handleModalClose} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default Top35EarningsModal;
