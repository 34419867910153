const Endpoint = {
  Register: '/auth/register',
  Verify: '/auth/verify',
  Login: '/auth/authenticate',
  Forgot: '/auth/forgot_password',
  Recover: '/auth/verify_forgot_password',
  Refresh: '/auth/refresh'
};

export default Endpoint;
