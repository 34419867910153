import React, { useState, useRef, useContext } from "react";
import {
  Button,
  Form,
  Responsive,
  Segment,
  Input,
  Message,
  MessageContent
} from "semantic-ui-react";
import { RouteComponentProps, Link } from "react-router-dom";
import { StateContext } from "../state";

import httpClient from "./api/HTTPClient";
import Endpoint from "./api/Endpoint";

interface IVerifyInput {
  username: string;
  code: string;
}

interface IVerifyState {
  isBusy: boolean;
  error: {
    message: string;
  };
}

const Verify = ({
  match,
  history,
  location: { state: passedState }
}: RouteComponentProps<
  {},
  {},
  {
    // parameters passed to this page after registration
    name: string;
    password: string;
    username: string;
  }
>) => {
  const [{ carrierProduct }] = useContext(StateContext);
  const passedUsername = passedState ? passedState.username : "";
  const verifyInput = useRef<IVerifyInput>({
    username: passedUsername,
    code: ""
  }).current;
  const [verifyState, setVerifyState] = useState<IVerifyState>({
    isBusy: false,
    error: {
      message: ""
    }
  });

  const { isBusy, error } = verifyState;

  const handleSubmit = async () => {
    const { code, username } = verifyInput;
    if (!username || !username.trim().length) {
      setVerifyState({
        isBusy: false,
        error: {
          message: "You must enter your email address"
        }
      });
      return;
    }
    if (!code || code.trim().length < 6) {
      setVerifyState({
        isBusy: false,
        error: {
          message: "You must enter the verification code"
        }
      });
      return;
    }
    try {
      setVerifyState({ isBusy: true, error: { message: "" } });
      const { data, status } = await httpClient.post(
        Endpoint.Verify,
        verifyInput
      );
      if (status !== 200 || !data.success) {
        throw new Error(data.message || "The verification was not successful.");
      }
      history.push(carrierProduct.pathPrefix, {
        message:
          "You're account has been verified successfully. Please login to continue"
      });
    } catch (exp) {
      setVerifyState({
        isBusy: false,
        error: {
          message: typeof exp === "string" ? exp : exp.message
        }
      });
    }
  };

  return (
    <>
      {carrierProduct && (
        <div style={{ maxWidth: 500, margin: "16px auto" }}>
          <Segment.Group size="massive" raised>
            <Responsive as={Segment}>
              <Form onSubmit={handleSubmit}>
                {!isBusy && (
                  <Message>
                    <MessageContent>
                      <p>
                        Please enter the code that has been sent to your email{" "}
                        {!!passedUsername && (
                          <strong>({passedUsername})</strong>
                        )}{" "}
                        for verifying your account.
                      </p>
                    </MessageContent>
                  </Message>
                )}
                <Form.Field
                  control={Input}
                  label="Email"
                  type="email"
                  icon="envelope"
                  iconPosition="left"
                  placeholder="Email"
                  defaultValue={verifyInput.username}
                  onChange={(event: any) =>
                    (verifyInput.username = event.target.value)
                  }
                />
                <Form.Field
                  control={Input}
                  label="Verification code"
                  type="text"
                  icon="keyboard"
                  iconPosition="left"
                  placeholder="Eg. 234512"
                  onChange={(event: any) =>
                    (verifyInput.code = event.target.value)
                  }
                />
                {!!error.message && (
                  <Message negative>
                    <MessageContent>{error.message}</MessageContent>
                  </Message>
                )}
                <Button
                  loading={isBusy}
                  type="submit"
                  disabled={isBusy}
                  fluid
                  primary
                >
                  {isBusy ? "Verifying..." : "Verify"}
                </Button>
                <p>
                  Already verified?{" "}
                  <Link to={carrierProduct.pathPrefix + "auth/login"}>
                    Login
                  </Link>
                </p>
              </Form>
            </Responsive>
          </Segment.Group>
        </div>
      )}
    </>
  );
};

export default Verify;
