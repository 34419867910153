import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import {
  Link,
  Route,
  Switch,
  useLocation,
  Redirect,
  useHistory
} from "react-router-dom";
import { Button, Container, Grid, Responsive } from "semantic-ui-react";

import AppHeader from "./AppHeader";
import BreadcrumbBar from "./BreadcrumbBar";
import IndexView from "./views/IndexView";
import TableView from "./views/TableView";
import EntryModal from "./EntryModal";
import FaqModal from "./FaqModal";
import ValuesView from "./views/ValuesView";
import YearSelectView from "./views/YearSelectView";
import TermsOfUse from "./TermsOfUse";
import { StateContext } from "./state";
import { LOCAL_USER_KEY } from "./auth/Login";

const App: React.FC = () => {
  let location = useLocation();
  const [{ carrierProduct }, dispatch] = useContext(StateContext);
  const history = useHistory();
  const [showEntryModal, setEntryShowModal] = useState("true");
  const [showFaqModal, setShowFaqModal] = useState(false);

  const useStateWithLocalStorage = (
    localStorageKey: string,
    initialValue?: string
  ) => {
    const [value, setValue] = useState<any>(
      localStorage.getItem(localStorageKey) || initialValue
    );
    useEffect(() => {
      localStorage.setItem(localStorageKey, value);

      if (value === "true") {
        setEntryShowModal("false");
      } else {
        setEntryShowModal("true");
      }
    }, [localStorageKey, value]);

    return [value, setValue];
  };

  const [userAcceptedTerms, setUserAcceptedTerms] = useStateWithLocalStorage(
    "userAcceptedTermsOfService",
    "false"
  );

  const callbackModal = () => {
    if (userAcceptedTerms === "false") {
      setUserAcceptedTerms("true");
    }
  };

  const toggleFaq = () => {
    let faqState = !showFaqModal;
    setShowFaqModal(faqState);
  };

  // Responsive
  const [viewWidth, setViewWidth] = useState(0);
  const [appPadding, setAppPadding] = useState({ padding: "0" });
  useEffect(() => {
    if (viewWidth < Responsive.onlyTablet.minWidth!) {
      setAppPadding({ padding: "1rem 0 0" });
    } else if (viewWidth > Responsive.onlyTablet.minWidth!) {
      setAppPadding({ padding: "3rem" });
    }
  }, [viewWidth]);
  const handleOnUpdate = (e: any, viewWidth: any) => {
    setViewWidth(viewWidth.width);
  };

  return (
    <>
      {carrierProduct && (
        <Responsive
          as="div"
          fireOnMount
          onUpdate={handleOnUpdate}
          className="app"
          style={appPadding}
        >
          <Container className="app-container">
            {/* <a
              href="https://us.milliman.com"
              className={`app-logo ${userAcceptedTerms ? "is-animated" : ""}`}
            >
              <Image src={"./temp_logo.svg"} size="mini" />
            </a> */}

            <div className="app-inner">
              <AppHeader
                carrierPathId={carrierProduct.carrierPathId}
                carrierPathPrefix={carrierProduct.pathPrefix}
              />
              <Grid
                verticalAlign="middle"
                columns={2}
                style={{ marginBottom: "1em" }}
              >
                <>
                  <Grid.Column>
                    <BreadcrumbBar location={location} />
                  </Grid.Column>

                  <Grid.Column floated="right" textAlign="right">
                    <Button
                      content="FAQs"
                      size="tiny"
                      icon="question"
                      labelPosition="right"
                      onClick={toggleFaq}
                    />
                    <Button
                      content="Logout"
                      size="tiny"
                      onClick={() => {
                        history.push(carrierProduct.pathPrefix);
                        localStorage.removeItem(LOCAL_USER_KEY);
                        dispatch({ type: "logout" });
                      }}
                      negative
                      basic
                    />
                  </Grid.Column>
                </>
              </Grid>
              <Switch>
                <Route
                  path={carrierProduct.pathPrefix}
                  exact={true}
                  render={() => (
                    <YearSelectView
                      handleModalClose={toggleFaq}
                      carrierPathId={carrierProduct.carrierPathId}
                      carrierPathPrefix={carrierProduct.pathPrefix}
                    />
                  )}
                />
                <Route
                  path={
                    carrierProduct.pathPrefix +
                    "index-birth-year/value/calculations"
                  }
                  exact={true}
                  render={() => <TableView />}
                />
                <Route
                  path={carrierProduct.pathPrefix + "index-birth-year/value"}
                  exact={true}
                  render={() => <ValuesView />}
                />
                <Route
                  path={carrierProduct.pathPrefix + "index-birth-year"}
                  exact={true}
                  render={() => <IndexView />}
                />
                <Route
                  path="/accept-terms"
                  exact={true}
                  render={() => <TermsOfUse />}
                />
                <Redirect path="*" to={carrierProduct.pathPrefix} />
              </Switch>
            </div>
            <footer className="app-footer">
              <Link target="_blank" to="/accept-terms">
                Terms of Use
              </Link>
            </footer>
          </Container>
        </Responsive>
      )}
      <EntryModal
        show={JSON.parse(showEntryModal)}
        handleModalClose={callbackModal}
      />
      <FaqModal show={showFaqModal} handleModalClose={toggleFaq} />
    </>
  );
};

export default App;
