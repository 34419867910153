import React, { useState, useEffect } from 'react';

import {
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';

const helper = require('./helpers');

const convertData = (data: any) => {
  let newData: Array<any> = [];
  Object.values(data).forEach((value: any, index: number) => {
    let obj = { year: null, value: null, barIndex: 0 };
    obj.year = value.year;
    obj.value = value.index;
    obj.barIndex = index;
    newData.push(obj);
  });
  return newData;
};

const yAxisTickFormatter = (value: any) => helper.CurrencyFormatter(value, 0);

const CustomizedTooltip = (props: any) => {
  if (props.active) {
    const { label, payload } = props;
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: 'rgba(255,255,255,0.9',
          padding: '5px 8px',
        }}
      >
        <div style={{ marginBottom: '3px' }}>Year: {label}</div>
        <div style={{ marginBottom: '3px' }}>
          Index:{' '}
          {helper.CurrencyFormatter(
            payload.length > 0 ? payload[0].payload.value : 'N/A',
            2
          )}
        </div>
      </div>
    );
  }
  return <div />;
};

const IndexBarChart = (props: any) => {
  const [chartData, setChartData] = useState<any>();

  useEffect(() => {
    setChartData(convertData(props.chartData));
  }, [props.chartData]);

  return (
    <>
      <ResponsiveContainer width="99%">
        <BarChart
          data={chartData}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorIndexBar" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#095386" stopOpacity={0.7} />
              <stop offset="95%" stopColor="#C0DCB1" stopOpacity={0.7} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis dataKey="year" interval={0} />
          <YAxis tickFormatter={yAxisTickFormatter} />
          <Tooltip
            cursor={{ fill: '#000000', fillOpacity: 0.05 }}
            content={<CustomizedTooltip />}
          />
          <Bar
            stroke="transparent"
            dataKey="value"
            fill="url(#colorIndexBar)"
            fillOpacity={0.7}
            onClick={(e) => props.handleBarClick(e)}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default IndexBarChart;
