import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { StateProvider, StateContext } from "./state";
import AuthScreen from "./auth";
import { LOCAL_USER_KEY } from "./auth/Login";
// Google Analytics
// Standard implementation markup in index.html
// import ReactGA from 'react-ga';
// const trackingId = "UA-131266182-6";
// ReactGA.initialize(trackingId, { standardImplementation: true });

// ReactGA.initialize(trackingId, { debug: true });
// ReactGA.pageview(window.location.pathname + window.location.search);
import usePageTracking from "./Hooks/usePageTracking";

// STATE
// currentYear is set as an env variable in Amplify to test other branches
//  before the next year. Have to have value as a default, so setting it to a
// string of 'null' to check against.
const initialState = {
  entryData: null,
  summaryData: null,
  cohortSummaryData: null,
  selectedIndexData: null,
  selectedValueData: null,
  user: JSON.parse(localStorage.getItem(LOCAL_USER_KEY) || "{}"),
  carrierProduct: null,
  yearSpan: null,
  currentYear:
    process.env.REACT_APP_CURRENT_YEAR !== "null"
      ? process.env.REACT_APP_CURRENT_YEAR
      : new Date().getFullYear(),
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "updateEntryData":
      return {
        ...state,
        entryData: action.newEntryData,
      };
    case "updateSummaryData":
      return {
        ...state,
        entryData: action.newSummaryData,
      };
    case "updateCohortData":
      return {
        ...state,
        cohortSummaryData: action.newCohortSummaryData,
      };
    case "updateSelectedIndexData":
      return {
        ...state,
        selectedIndexData: action.newSelectedIndexData,
      };
    case "updateSelectedValueData":
      return {
        ...state,
        selectedValueData: action.newSelectedValueData,
      };
    case "updateYearSpan":
      return {
        ...state,
        yearSpan: action.newYearSpan,
      };
    case "login":
      return {
        ...state,
        user: action.user,
      };
    case "logout":
      return {
        ...state,
        user: {},
      };
    case "updateCarrierProduct":
      return {
        ...state,
        carrierProduct: action.newCarrierProduct,
      };
    default:
      return state;
  }
};

const Main = () => {
  let location = useLocation();
  const [{ user }, dispatch] = useContext(StateContext);
  const isLoggedIn = (user && user.userId) !== undefined;
  usePageTracking(isLoggedIn);
  const Content = isLoggedIn ? App : AuthScreen;

  // ====================
  // const [{ carrierProduct }, dispatch] = useContext(StateContext);
  // let location = useLocation();

  // // Set carrier product in state
  // const updateCarrierProduct = (currentProduct: Object) => {
  //   dispatch({
  //     type: "updateCarrierProduct",
  //     newCarrierProduct: currentProduct
  //   });
  // };

  // if (carrierProduct) {
  //   console.log("RENDER", carrierProduct.id);
  // }

  // useEffect(() => {
  //   // get 3 digit carrier product code from URL path
  //   const locationPath = location.pathname.substr(1, 3);
  //   // console.log("PATH", location.pathname.split("/"));

  //   const validProduct = indexOptions.filter(p => {
  //     return p.key === locationPath;
  //   });

  //   console.log("validProduct", validProduct);

  //   if (validProduct.length && !carrierProduct) {
  //     updateCarrierProduct(validProduct[0]);
  //   } else {
  //     updateCarrierProduct({ key: "", text: "", value: "" });
  //   }
  // }, [location.pathname]);

  let carrierPathId = location.pathname.substr(1, 3);

  if (carrierPathId !== "201" && carrierPathId !== "202") {
    carrierPathId = "";
  }

  let pathPrefix = carrierPathId ? "/" + carrierPathId + "/" : "/";

  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;

    dispatch({
      type: "updateCarrierProduct",
      newCarrierProduct: {
        carrierPathId: carrierPathId,
        pathPrefix: pathPrefix,
      },
    });

    return () => {
      isMounted.current = false;
    };
  }, [location.pathname, carrierPathId, pathPrefix, dispatch]);

  return <Content />;
};
ReactDOM.render(
  <StateProvider initialState={initialState} reducer={reducer}>
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Main />
      </QueryParamProvider>
    </Router>
  </StateProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
