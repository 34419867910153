import { useState } from "react";

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  //const [content, setContent] = useState("");

  const toggleModal = () => {
    setIsShowing(!isShowing);
  };

  return {
    isShowing,
    toggleModal,
  };
};

export default useModal;
