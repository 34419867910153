import React, { useState, useContext, useEffect } from "react";
import { Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { withRouter, Redirect, useLocation } from "react-router-dom";
import { useQueryParams, StringParam } from "use-query-params";
import { StateContext } from "../state";

interface IValues {
  /* Key value pairs for all the field values with key being the field name */
  [key: string]: any;
}

const YearSelectView = (props: any) => {
  let location = useLocation();
  const [{ carrierProduct, currentYear }] = useContext(StateContext);

  const [queryParams, setQueryParams] = useQueryParams({
    indexOption: StringParam,
    yob: StringParam,
    indexYear: StringParam,
    valueIndex: StringParam,
    valueIncome: StringParam,
    valueSsStart: StringParam,
    yobIndex: StringParam,
    startYear: StringParam,
    endYear: StringParam,
  });

  useEffect(() => {
    if (queryParams.yob) {
      setQueryParams({
        indexOption: undefined,
        yob: undefined,
        indexYear: undefined,
        valueIndex: undefined,
        valueIncome: undefined,
        valueSsStart: undefined,
        yobIndex: undefined,
        startYear: undefined,
        endYear: undefined,
      });
    }
  }, []);

  const [goToIndexRoute, setGoToIndexRoute] = useState(false);

  // if props.carrierProd.key is empty string - then show all in drop down
  // if props.carrierProd.key has string length - then use the carrierProd only in dropdown
  let indexOptions = [
    {
      key: "000",
      text: "Choose product",
      value: "",
    },
    {
      key: "201",
      text: "American Life PlanGap® Annuity",
      value: "American Life PlanGap® Annuity",
    },
    {
      key: "202",
      text: "North American Secure Horizon℠ Plus",
      value: "North American Secure Horizon℠ Plus",
    },
  ];

  if (carrierProduct.carrierPathId) {
    const validProduct = indexOptions.filter((p) => {
      return p.key === carrierProduct.carrierPathId;
    });

    indexOptions = validProduct;
  }

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  const yearOptions: any = () => {
    const startRange = 1930;
    const endRange = currentYear - 18;
    const yearRange = range(startRange, endRange, 1);

    let newArray: Array<any> = [];

    yearRange.forEach((item) => {
      newArray.push({
        key: item,
        text: item,
        value: item,
      });
    });
    return newArray;
  };

  const formControls: IValues = {
    yearOfBirth: {
      value: "",
    },
    indexOption: {
      value: indexOptions[0].value,
      key: indexOptions[0].key,
    },
  };

  const [formData, setFormData] = useState(formControls);

  const handleChange = (event: any, data: any) => {
    const name: any = data.name;
    const value: any = data.value;

    setFormData({
      ...formData,
      [name]: { ...formData[name], value },
    });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!formData.yearOfBirth.value || !formData.indexOption.value) {
      alert("Please choose a Year of Birth and Product.");
    } else {
      setQueryParams({
        indexOption: formData.indexOption.value,
        yob: formData.yearOfBirth.value,
      });
      setGoToIndexRoute(true);
    }
  };

  if (goToIndexRoute) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: carrierProduct.pathPrefix + "index-birth-year",
          state: {
            carrierPathId: carrierProduct.carrierPathId,
            carrierPathPrefix: carrierProduct.pathPrefix,
          },
        }}
      />
    );
  }
  return (
    <Segment raised padded>
      <Grid divided columns={2}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Header as="h3">
              Welcome to the Social Security Benefit Index (SSBI)
            </Header>
            <p>
              We aim to provide transparency in SSBI calculations, demonstrate
              how the SSBI is calculated, and provide a reference point for
              index information.
            </p>
            <p>
              To begin, enter a year of birth and choose the Index to show the
              Social Security Benefit Index.
            </p>
            <p style={{ paddingBottom: "1rem" }}>
              Have questions?{" "}
              <a href="#!" onClick={props.handleModalClose}>
                See our FAQs <Icon size="small" name="arrow right" />
              </a>
            </p>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Form size="large" onSubmit={handleSubmit}>
              <Form.Field>
                <Form.Select
                  fluid
                  label="Year of Birth"
                  options={yearOptions()}
                  placeholder="Choose year"
                  name="yearOfBirth"
                  value={formData.yearOfBirth.value}
                  onChange={handleChange}
                />
              </Form.Field>
              <Form.Field>
                {indexOptions.length === 1 && (
                  <Form.Input
                    fluid
                    label="Product"
                    value={indexOptions[0].value}
                    name="indexOption"
                    readOnly
                  />
                )}
                {indexOptions.length > 1 && (
                  <Form.Select
                    fluid
                    label="Product"
                    options={indexOptions}
                    placeholder="Choose product"
                    name="indexOption"
                    value={formData.indexOption.value}
                    onChange={handleChange}
                  />
                )}
              </Form.Field>

              <Form.Button primary fluid size="big">
                Show Index
              </Form.Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default withRouter(YearSelectView);
