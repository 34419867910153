import * as React from "react";
import { withRouter } from "react-router-dom";
import { Header } from "semantic-ui-react";

const TermsOfUse = (props: any) => {
  return (
    <>
      <Header>
        <strong>SOCIAL SECURITY BENEFIT INDEX TERMS OF USE</strong>
      </Header>

      <p>
        THESE TERMS OF USE ARE A LEGAL AGREEMENT BETWEEN THE USER AND MILLIMAN,
        INC. (“Milliman”). The term “User” refers to both the User as an
        individual and, if applicable, the entity for which User is the
        authorized representative at the time of any access to the Social
        Security Benefit Index and other calculations, data, information,
        values, or other material available through this site (“SSBI”).
        Carefully read the terms of these provisions before accessing or using
        the SSBI. By accessing or using the SSBI, User agrees to be bound by the
        terms of these provisions. This agreement is conditioned on User’s
        acceptance without modification of the terms, conditions, and notices
        contained herein. If User does not agree with these Terms of Use, User
        may not proceed and may not use the SSBI.
      </p>

      <p>
        In consideration of the foregoing and the promises and agreements set
        forth below, Milliman and User agree as follows:
      </p>

      <p>
        <strong>License.</strong> Milliman and User acknowledge that Milliman
        releases the SSBI solely for informational, illustrative, and research
        purposes. Subject to these Terms of Use, Milliman grants User a
        nonexclusive, nontransferable, limited license to access and use the
        SSBI during the term of these Terms of Use for its internal purposes as
        set forth herein.
      </p>

      <p>
        <strong>Restrictions.</strong> User shall not (a) license, sublicense,
        transfer, sell, resell, publish, reproduce, and/or otherwise distribute
        or redistribute the SSBI or any components thereof in any manner
        (including, but not limited to, via or as part of any Internet site); or
        (b) provide access to the SSBI or any portion thereof to any person,
        firm or entity, including, without limitation, any entity that is
        affiliated with User. User shall take all precautions that are
        reasonably necessary to prevent any unauthorized access, use,
        distribution, or redistribution of the SSBI. User shall not (a) use the
        SSBI as part of User’s intranet or other internal network; (b) create
        archival or derivative works based on the SSBI or any portion thereof;
        or (c) modify, reverse-engineer, disassemble, decompile or store the
        SSBI or any software contained therein.
      </p>

      <p>
        <strong>
          USER IS PROHIBITED FROM (I) USING THE SSBI OR INFORMATION RELATED
          THERETO OR INCLUDED THEREIN, AS THE INPUT VALUE OR THE CONSTITUENT FOR
          THE CREATION, STRUCTURING, DEVELOPING, CALCULATION, MAINTENANCE,
          PUBLICATION, DISTRIBUTION; ANY OTHER MEANS TO DERIVE OR ESTABLISH A
          FINANCIAL INDEX OR GUIDELINE OR INVESTMENT STRATEGY; (II) USING THE
          SSBI OR INFORMATION RELATED THERETO OR INCLUDED THEREIN, AS A
          COMPONENT OF ANY SETTLEMENT VEHICLE OR VALUE OR ANY FINANCIAL
          INSTRUMENT TO BE DEVELOPED, ISSUED, TRADED, SOLD, MARKETED AND/OR
          PROMOTED BY USER, INCLUDING, WITHOUT LIMITATION, A TREND GUARANTEE,
          RATE GUARANTEE OR OTHER INSTRUMENT WHOSE SETTLEMENT PRICE, CAPITAL
          AND/OR INCOME VALUE IS CALCULATED BASED ON CHANGES IN VALUE OF ANY
          SSBI IN WHOLE OR IN PART; OR (III) EMBEDDING THE SSBI OR INFORMATION
          RELATED THERETO OR INCLUDED THEREIN, IN ANY PRODUCT, SERVICE, OR
          SOLUTION THAT USER WILL MAKE AVAILABLE TO THIRD PARTIES.
        </strong>
      </p>

      <p>
        <strong>Non-Disclosure.</strong> User acknowledges that the SSBI has
        been developed at great expense to Milliman. To protect Milliman’s
        interest in the SSBI, User agrees that it will not, directly or
        indirectly, disclose the SSBI or any portion thereof to any nonemployee
        of User or other third party, or, except as authorized under these Terms
        of Use, permit any copies of the SSBI or any portion thereof to be made
        without Milliman’s prior written consent. User agrees to take all
        necessary precautions to prevent any unauthorized copying, use or
        disclosure of the SSBI or any portion thereof.
      </p>

      <p>
        <strong>Publicity.</strong> User agrees that it shall not use Milliman’s
        name, trademarks or service marks, or refer to Milliman or the SSBI,
        directly or indirectly, in any media release, public announcement or
        public disclosure, including in any promotional or marketing materials,
        customer lists, referral lists, websites or business presentations
        without Milliman’s prior written consent for each such use or release,
        which consent shall be given in Milliman’s sole discretion.
      </p>

      <p>
        <strong>Ownership; Injunctive Relief.</strong> Between User and
        Milliman, all patents, copyrights, mask works, trade secrets, trademarks
        and other proprietary rights in or related to the SSBI are and will
        remain the exclusive property of Milliman. User will not take any action
        that jeopardizes Milliman’s proprietary rights nor will it acquire any
        right in the SSBI other than rights granted in these Terms of Use. The
        parties acknowledge and agree that damages would be an inadequate remedy
        for Milliman resulting from a breach by User of its obligations
        hereunder and that Milliman shall be entitled to injunctive relief in
        the event of such a breach, in addition to any other remedy at law or in
        equity which may otherwise be available to Milliman.
      </p>

      <p>
        <strong>Warranty Disclaimer.</strong> MILLIMAN DOES NOT GUARANTEE THE
        ADEQUACY, ACCURACY, TIMELINESS OR COMPLETENESS OF THE SSPI OR ANY
        COMPONENT THEREOF. MILLIMAN SPECIFICALLY DISCLAIMS ALL OTHER WARRANTIES
        AND CONDITIONS, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, ANY
        IMPLIED WARRANTY OR CONDITION OF MERCHANTABILITY OR FITNESS FOR A
        PARTICULAR PURPOSE WITH RESPECT TO THE SSBI. MILLIMAN DOES NOT WARRANT
        THAT THE SSBI WILL BE FREE OF BUGS OR PROGRAM ERRORS. User acknowledges
        that it is responsible for ensuring the SSBI are properly used by User
        or in User’s business. User acknowledges and agrees that proper use of
        the SSBI requires experience and judgment and User will take all
        reasonably necessary action to ensure that User or the designated
        employees of User utilizing the SSBI possess such qualifications.
      </p>

      <p>
        <strong>Limitation on Liability.</strong> UNDER NO CIRCUMSTANCES SHALL
        MILLIMAN BE LIABLE FOR INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES
        SUFFERED BY USER, INCLUDING ANY LOST PROFITS, EVEN IF MILLIMAN HAS BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. MILLIMAN AND ITS OFFICERS,
        DIRECTORS, AGENTS AND EMPLOYEES SHALL NOT BE LIABLE TO USER UNDER ANY
        THEORY OF LAW INCLUDING NEGLIGENCE, TORT, BREACH OF CONTRACT OR
        OTHERWISE, FOR ANY DAMAGES IN EXCESS OF THE TOTAL AMOUNT PAID BY USER TO
        MILLIMAN HEREUNDER IN THE 12-MONTH PERIOD PRECEDING THE CLAIM OR $1,000,
        WHICHEVER IS LESS.
      </p>

      <p>
        <strong>Governing Law; Disputes.</strong> These Terms of Use shall be
        governed by, subject to, and interpreted in accordance with the laws of
        the state of New York, without regard to conflict of laws principles. In
        the event of any dispute arising out of or relating to these Terms of
        Use or the SSBI, the parties agree that the dispute will be resolved by
        final and binding arbitration under the Commercial Arbitration Rules of
        the American Arbitration Association. The arbitration shall take place
        before a panel of three arbitrators. Within 30 days of the commencement
        of the arbitration, each party shall designate in writing a single
        neutral and independent arbitrator. The two arbitrators designated by
        the parties shall then select a third arbitrator. The arbitrators shall
        have a background in insurance, actuarial science or law. The
        arbitrators shall have the authority to permit limited discovery,
        including depositions, prior to the arbitration hearing. Such discovery
        shall be conducted consistent with the Federal Rules of Civil Procedure.
        The arbitrators shall have no power or authority to award punitive or
        exemplary damages. Any award made may be confirmed in any court having
        jurisdiction. Any arbitration shall be confidential and, except as
        required by law, neither party may disclose the existence, content or
        results of any arbitration hereunder without the prior written consent
        of the other party, except that disclosure is permitted to a party’s
        auditors and legal advisors
      </p>

      <p>
        <strong>Term; Termination.</strong> These Terms of Use shall commence as
        of the date of User’s acceptance hereof and continue until terminated
        pursuant to this section. Either party may terminate these Terms of Use
        for convenience and without penalty at any time by providing written
        notification to the other party. The license to use the SSBI granted
        herein shall terminate if User fails to comply with its obligations
        hereunder and, upon notice of such termination, User shall immediately
        destroy all copies and delete any electronic copies of the SSBI from
        User’s systems.
      </p>

      <p>
        <strong>Miscellaneous.</strong> These Terms of Use constitutes the
        entire agreement between the parties relating to the subject matter
        hereof and shall supersede all prior or contemporaneous written or oral
        understanding or agreements. These Terms of Use may be amended only by a
        written document signed by duly authorized representatives of Milliman
        and User. No waiver by either party of any breach by the other shall be
        valid unless in writing and no such waiver shall extend to any other
        breach by either party. User agrees that it will not transfer the SSBI
        or assign its rights under these Terms of Use unless the prior written
        consent of Milliman is obtained. These Terms of Use is binding upon and
        inures to the benefit of Milliman and User and their successors and
        permitted assigns.
      </p>

      <p>
        <strong>
          BY ACCEPTING THESE TERMS OF USE, USER REPRESENTS AND WARRANTS THAT
          USER IS DULY AUTHORIZED TO ACCEPT THE TERMS AND CONDITIONS OF THESE
          TERMS OF USE ON BEHALF OF USER’S ORGANIZATION.
        </strong>
      </p>

      <p>
        For information on the Milliman Privacy Policy go to:{" "}
        <a
          href="https://us.milliman.com/en/privacy%20policy"
          target="_blank"
          title="Milliman Privacy Policy"
          rel="noopener noreferrer"
        >
          https://us.milliman.com/en/privacy%20policy
        </a>
      </p>
    </>
  );
};

export default withRouter(TermsOfUse);
