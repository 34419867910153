import React, { useState, useCallback, useContext, useEffect } from "react";
import {
  Button,
  Form,
  Responsive,
  Segment,
  Input,
  Message,
  MessageContent
} from "semantic-ui-react";
import { useQueryParams, StringParam } from "use-query-params";
import { RouteComponentProps, Link, withRouter } from "react-router-dom";

import { StateContext } from "../state";
import httpClient from "./api/HTTPClient";
import Endpoint from "./api/Endpoint";

export const LOCAL_USER_KEY = "@benefit_user@";

interface IState {
  userId?: string | null;
  userError?: { content: string; pointing: string };
  passwordError?: { content: string; pointing: string };
  password?: string | null;
  hasAgreed: boolean;
}

const Login = ({
  location: { state: passedState }
}: RouteComponentProps<
  {},
  {},
  {
    message: string;
    carrierPathPrefix: string;
  }
>) => {
  const [{ carrierProduct }, dispatch] = useContext(StateContext);
  const [apiState, setApiState] = useState({
    message: passedState ? passedState.message : "",
    isBusy: false
  });

  const [params] = useQueryParams({
    userId: StringParam,
    password: StringParam
  });

  const [state, setState] = useState<IState>({
    userId: null,
    userError: undefined,
    passwordError: undefined,
    password: null,
    hasAgreed: false,
    ...params
  });

  const { userId, password, userError, passwordError } = state;
  const { message, isBusy } = apiState;

  const handleSubmit = useCallback(async () => {
    if (!userId || !userId.trim().length) {
      setState({
        ...state,
        userError: {
          content: "Please enter a valid user id.",
          pointing: "below"
        }
      });
      return;
    }
    if (!password || !password.trim().length || password.trim().length < 6) {
      setState({
        ...state,
        passwordError: {
          content: "Please enter a password with at least 6 characters.",
          pointing: "below"
        }
      });
      return;
    }
    // if (!hasAgreed) {
    //   alert('You must agree to the terms and conditions.');
    //   return;
    // }

    // if (userId !== 'benefitindex' || password !== 'pass123') {
    //   setState({
    //     ...state,
    //     userError: {
    //       content: 'Invalid userid or password.',
    //       pointing: 'below',
    //     },
    //   });
    //   return;
    // }

    try {
      setApiState({ ...apiState, isBusy: true });
      const { data, status } = await httpClient.post(Endpoint.Login, {
        username: userId,
        password
      });
      if (!data.success || status !== 200) {
        throw new Error(data.message || "The username/password is not valid.");
      }
      const user = { userId, ...data.result.AuthenticationResult };
      localStorage.setItem(LOCAL_USER_KEY, JSON.stringify(user));
      dispatch({
        type: "login",
        user
      });
    } catch (exp) {
      setApiState({
        message: typeof exp === "string" ? exp : exp.message,
        isBusy: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, state]);

  useEffect(() => {
    if (params.userId && params.password) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <>
      {carrierProduct && (
        <div style={{ maxWidth: 500, margin: "16px auto" }}>
          <Segment.Group size="massive" raised>
            <Responsive as={Segment}>
              <Form onSubmit={handleSubmit}>
                <Form.Field
                  control={Input}
                  label="User ID"
                  error={userError}
                  type="text"
                  icon="user"
                  iconPosition="left"
                  placeholder="User ID"
                  defaultValue={userId}
                  onChange={(event: any) =>
                    setState({
                      ...state,
                      userError: undefined,
                      userId: event.target.value
                    })
                  }
                />
                <Form.Field
                  control={Input}
                  label="Password"
                  error={passwordError}
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  defaultValue={password}
                  onChange={(event: any) =>
                    setState({
                      ...state,
                      passwordError: undefined,
                      password: event.target.value
                    })
                  }
                />
                {/* <Form.Field>
              <Checkbox
                onChange={() => setState({ ...state, hasAgreed: !hasAgreed })}
                label="I agree to the Terms and Conditions"
                value={+hasAgreed}
              />
            </Form.Field>  */}
                {!!message && (
                  <Message>
                    <MessageContent>{message}</MessageContent>
                  </Message>
                )}
                <Button
                  loading={isBusy}
                  disabled={isBusy}
                  type="submit"
                  primary
                  fluid
                >
                  {isBusy ? "Logging in..." : "Login"}
                </Button>
                <p>
                  Don't have an account?{" "}
                  <Link to={carrierProduct.pathPrefix + "auth/register"}>
                    Register now
                  </Link>
                </p>

                <Link
                  to={carrierProduct.pathPrefix + "auth/forgot"}
                  className="ui basic button animated fade fluid"
                >
                  <span className="visible content">Forgot password?</span>
                  <span className="hidden content">
                    Recover the password using email
                  </span>
                </Link>
              </Form>
            </Responsive>
          </Segment.Group>
        </div>
      )}
    </>
  );
};

export default withRouter(Login);
