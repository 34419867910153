import React, { useState, createRef } from "react";

import { Accordion, Button, Header, Icon, Ref, Table } from "semantic-ui-react";

import useModal from "./Hooks/useModal";
import CalcTablePopper from "./CalcTablePopper";
import Top35IndexedEarningsModal from "./Top35IndexedEarningsModal";

const helper = require("./helpers");

const CalcTable = (props: any) => {
  // console.log("THE PROPS IN CALC ", props.tableData);
  const contextRef = createRef();

  const { isShowing, toggleModal } = useModal();

  const [accDisplay, setAccDisplay] = useState({
    isAccActive: false,
    styles: { display: "block", overflow: "hidden", height: "300px" },
  });

  const handleModal = (event: any) => {
    event.preventDefault();
    toggleModal();
  };

  const handleAccordion = (e: any, props: any) => {
    // if the accordion for the values table is active,
    // set the styles to allow the table to be shown
    if (props.active === true) {
      setAccDisplay({
        isAccActive: !props.active,
        styles: { display: "block", overflow: "hidden", height: "300px" },
      });
    } else {
      setAccDisplay({
        isAccActive: !props.active,
        styles: { display: "inherit", overflow: "visible", height: "auto" },
      });
    }
  };

  return (
    <>
      <Ref innerRef={contextRef}>
        <Accordion>
          <Accordion.Title>
            <Header size="medium">Value Calculations</Header>
          </Accordion.Title>
          <Accordion.Content
            active={accDisplay.isAccActive}
            style={accDisplay.styles}
            className="bi-accordion-content"
          >
            <Table stackable fixed>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Age</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Year</Table.HeaderCell>
                  <Table.HeaderCell>
                    Hypothetical Earnings{" "}
                    <CalcTablePopper content={"hypothetical earnings"} />
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    Benefit Base <CalcTablePopper content={"benefit base"} />
                  </Table.HeaderCell>
                  <Table.HeaderCell className="minimum-joyride">
                    Earnings/Base Minimum
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    National Avg. Wage Index{" "}
                    <CalcTablePopper content={"national average"} />
                  </Table.HeaderCell>
                  <Table.HeaderCell className="earnings-index-factor-joyride">
                    Earnings Indexing Factors
                  </Table.HeaderCell>
                  <Table.HeaderCell>Indexed Earnings</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {Object.entries(props.tableData.data).map(
                  (row: any, index: number) => {
                    const {
                      age,
                      year,
                      hypothetical_earnings,
                      contribution_benefit_base,
                      minimum_of_a_b,
                      national_average_wage_index,
                      earning_indexing_factors,
                      indexed_earnings,
                    } = row[1]; //destructuring

                    return (
                      <Table.Row key={index}>
                        <Table.Cell>{age}</Table.Cell>
                        <Table.Cell width={1}>{year}</Table.Cell>
                        {/* Hypothetical Earnings */}
                        <Table.Cell>
                          {hypothetical_earnings >= 0
                            ? helper.CurrencyFormatter(hypothetical_earnings, 2)
                            : ""}
                        </Table.Cell>
                        {/* Contribution/Benefit Base */}
                        <Table.Cell>
                          {contribution_benefit_base >= 0
                            ? helper.CurrencyFormatter(
                                contribution_benefit_base,
                                2
                              )
                            : ""}
                        </Table.Cell>
                        {/* Earnings/Benefit Base Minimum */}
                        <Table.Cell>
                          {minimum_of_a_b >= 0
                            ? helper.CurrencyFormatter(minimum_of_a_b, 2)
                            : ""}
                        </Table.Cell>
                        {/* National Avg. Wage Index */}
                        <Table.Cell>
                          {national_average_wage_index >= 0
                            ? helper.CurrencyFormatter(
                                national_average_wage_index,
                                2
                              )
                            : ""}
                        </Table.Cell>
                        {/* Earnings Indexing Factors */}
                        <Table.Cell>
                          {earning_indexing_factors >= 0
                            ? earning_indexing_factors.toPrecision(6)
                            : ""}
                        </Table.Cell>
                        {/* Indexed Earnings */}
                        <Table.Cell>
                          {indexed_earnings >= 0
                            ? helper.CurrencyFormatter(indexed_earnings, 2)
                            : ""}
                        </Table.Cell>
                      </Table.Row>
                    );
                  }
                )}
              </Table.Body>
            </Table>
            <div
              style={{
                position: accDisplay.isAccActive ? "relative" : "absolute",
                zIndex: 2,
                bottom: 0,
                width: "100%",
                paddingTop: "0.5rem",
                textAlign: "center",
              }}
            >
              <Button
                icon
                labelPosition="right"
                size="mini"
                active={accDisplay.isAccActive}
                onClick={handleAccordion}
              >
                {(accDisplay.isAccActive ? "Collapse" : "Expand") + " Table"}
                <Icon
                  name={accDisplay.isAccActive ? "chevron up" : "chevron down"}
                />
              </Button>
            </div>
          </Accordion.Content>
        </Accordion>
      </Ref>
      <Header size="medium">
        Average Indexed Monthly Earnings (AIME){" "}
        <CalcTablePopper content={"aime"} />
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="highest-earnings-joyride">
              {props.tableData.aime["1"].key}
              <a
                href="#!"
                onClick={(e) => handleModal(e)}
                style={{ display: "block" }}
              >
                View 35 Highest Indexed Earnings
              </a>
            </Table.HeaderCell>
            <Table.HeaderCell>{props.tableData.aime["2"].key}</Table.HeaderCell>
            <Table.HeaderCell className="aime-joyride">
              {props.tableData.aime["result"].key}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {helper.CurrencyFormatter(props.tableData.aime["1"].value, 2)}
            </Table.Cell>
            <Table.Cell>{props.tableData.aime["2"].value}</Table.Cell>
            <Table.Cell>
              {helper.CurrencyFormatter(props.tableData.aime["result"].value)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header size="medium">
        Primary Insurance Amount (PIA) <CalcTablePopper content={"pia"} />
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Bend Points</Table.HeaderCell>
            <Table.HeaderCell className="pia-joyride">Result</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="center">
              {props.tableData.pia["1"].value1}
            </Table.Cell>
            <Table.Cell>
              {helper.CurrencyFormatter(props.tableData.pia["1"].value2, 2)}
            </Table.Cell>
            <Table.Cell>{props.tableData.pia["1"].result}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell textAlign="center">
              {props.tableData.pia["2"].value1}
            </Table.Cell>
            <Table.Cell>
              {helper.CurrencyFormatter(props.tableData.pia["2"].value2, 2)}
            </Table.Cell>
            <Table.Cell>{props.tableData.pia["2"].result}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell textAlign="center">
              {props.tableData.pia["3"].value1}
            </Table.Cell>
            <Table.Cell>
              {helper.CurrencyFormatter(props.tableData.pia["3"].value2, 2)}
            </Table.Cell>
            <Table.Cell>{props.tableData.pia["3"].result}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell textAlign="center">
              {props.tableData.pia["result"].value1}
            </Table.Cell>
            <Table.Cell>
              {helper.CurrencyFormatter(
                props.tableData.pia["result"].value2,
                2
              )}
            </Table.Cell>
            <Table.Cell>{props.tableData.pia["result"].result}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header size="medium">
        COLA Adjustments <CalcTablePopper content={"cola"} />
      </Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Year</Table.HeaderCell>
            <Table.HeaderCell>Attained Age</Table.HeaderCell>
            <Table.HeaderCell>COLA</Table.HeaderCell>
            <Table.HeaderCell>PIA Adjusted for COLA</Table.HeaderCell>
            <Table.HeaderCell>Description</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.keys(props.tableData.cola_data).length === 0 &&
          props.tableData.cola_data.constructor === Object ? (
            <Table.Row>
              <Table.Cell>COLA Adjustments not applicable.</Table.Cell>
            </Table.Row>
          ) : (
            Object.entries(props.tableData.cola_data).map(
              (item: any, i: any) => {
                return (
                  <Table.Row key={i}>
                    <Table.Cell textAlign="center">{item[1].year}</Table.Cell>
                    <Table.Cell>{item[1].attained_age}</Table.Cell>
                    <Table.Cell>{item[1].cola}</Table.Cell>
                    <Table.Cell>
                      {helper.CurrencyFormatter(
                        item[1].pia_adjusted_for_cola,
                        2
                      )}
                    </Table.Cell>
                    <Table.Cell>{item[1].result_pia}</Table.Cell>
                  </Table.Row>
                );
              }
            )
          )}
        </Table.Body>
      </Table>

      <Header size="medium">Monthly Benefit and Annual Benefit Amount</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="early-delayed-factor-joyride">
              Early/Delayed Benefit Factor{" "}
              <CalcTablePopper content={"early factor"} />
            </Table.HeaderCell>
            <Table.HeaderCell className="mba-joyride">
              Monthly Benefit Amount (MBA)
            </Table.HeaderCell>
            <Table.HeaderCell className="aba-joyride">
              Annual Benefit Amount (ABA)
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {props.tableData.early_delayed_benefit_factor}
            </Table.Cell>
            <Table.Cell>
              {helper.CurrencyFormatter(
                props.tableData.monthly_benefit_amount,
                2
              )}
            </Table.Cell>
            <Table.Cell>
              {helper.CurrencyFormatter(props.tableData.annual_benefit_amount)}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Top35IndexedEarningsModal
        isShowing={isShowing}
        handleModalClose={toggleModal}
        modalData={props.tableData.top35_indexed_earnings}
      />
    </>
  );
};

export default CalcTable;
