import React, { useState, useContext, useEffect } from "react";
import { Grid, Header, Loader, Responsive, Segment } from "semantic-ui-react";
import { withRouter, Redirect, useLocation } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import { StateContext } from "../state";

import { useQueryParams, StringParam } from "use-query-params";

import IndexBarChart from "../IndexBarChart";
import httpClient from "../auth/api/HTTPClient";

configure({ axios: httpClient });

const IndexView = (props: any) => {
  const [{ yearSpan, carrierProduct, currentYear }, dispatch] =
    useContext(StateContext);

  let location = useLocation();
  const [queryParams, setQueryParams] = useQueryParams({
    yob: StringParam,
    indexYear: StringParam,
    indexOption: StringParam,
    yobIndex: StringParam,
  });
  const [goToValuesRoute, setGoToValuesRoute] = useState(false);

  const pathToFile: string = `/index/get_summary?yob=${queryParams.yob}&data_year=${currentYear}`;
  // Get data Summary data for chosen year
  const [{ data, error, loading }] = useAxios(pathToFile);

  const isMounted = React.useRef(false);

  useEffect(() => {
    isMounted.current = true;

    if (data && !yearSpan) {
      // console.log("DATA", data.data);
      var last = Object.keys(data.data).pop();
      const _yearSpan = {
        start: data.data[1].year,
        end: data.data[`${last}`].year,
      };

      dispatch({ type: "updateYearSpan", newYearSpan: _yearSpan });
    }

    return () => {
      isMounted.current = false;
    };
  }, [data, yearSpan, dispatch]);

  const handleBarAction = (barChartEventData: any) => {
    if (barChartEventData && data) {
      // If bar in chart is clicked, and there is a payload,
      // call fn in parent to add item to breadcrumb list

      // Add year of birth index to query string
      setQueryParams({
        indexYear: barChartEventData.payload.year,
        yobIndex: barChartEventData.payload.barIndex + 1,
      });
      // Route to next page with <Redirect />
      setGoToValuesRoute(true);
    }
  };

  const [viewWidth, setViewWidth] = useState("left");
  const indexTextAlign =
    viewWidth >= Responsive.onlyComputer.minWidth! ? "right" : "left";
  const chartHeight =
    viewWidth >= Responsive.onlyComputer.minWidth! ? "500px" : "300px";
  const lgThanTablet =
    viewWidth >= Responsive.onlyTablet.minWidth! ? true : false;
  const handleOnUpdate = (e: any, viewWidth: any) => {
    setViewWidth(viewWidth.width);
  };

  if (error || !queryParams.yob) {
    return <Redirect to={carrierProduct.pathPrefix} exact={true} />;
  }
  if (goToValuesRoute) {
    return (
      <Redirect
        to={{
          ...location,
          pathname: carrierProduct.pathPrefix + "index-birth-year/value",
          state: {
            carrierPathId: carrierProduct.carrierPathId,
            carrierPathPrefix: carrierProduct.pathPrefix,
          },
        }}
      />
    );
  }
  return (
    <section className="bi-m-section-top">
      {loading && <Loader size="big" active />}
      {!loading && data && (
        <Responsive
          as={Segment}
          fireOnMount
          onUpdate={handleOnUpdate}
          raised
          {...(lgThanTablet && { padded: true })}
        >
          <Header as="h2" size={lgThanTablet ? "large" : "medium"}>
            <Grid columns={2}>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                style={{ paddingBottom: 0 }}
              >
                Index for Birth Year {queryParams.yob ? queryParams.yob : ""}
              </Grid.Column>
              <Grid.Column
                mobile={16}
                tablet={8}
                computer={8}
                textAlign={indexTextAlign}
              >
                <Header sub>
                  <em>
                    {queryParams.indexOption ? queryParams.indexOption : ""}
                  </em>
                </Header>
              </Grid.Column>
            </Grid>
          </Header>
          <div style={{ marginTop: "3rem", height: chartHeight }}>
            {data && (
              <IndexBarChart
                chartData={data.data}
                handleBarClick={handleBarAction}
              />
            )}
          </div>
        </Responsive>
      )}
    </section>
  );
};

export default withRouter(IndexView);
