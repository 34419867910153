import React, { useState, useEffect, useContext } from "react";
import { Grid, Header, Loader, Responsive, Segment } from "semantic-ui-react";
import useAxios, { configure } from "axios-hooks";
import { Redirect } from "react-router-dom";
import { StateContext } from "../state";

import { useQueryParams, StringParam } from "use-query-params";

import Joyride, {
  ACTIONS,
  EVENTS,
  CallBackProps,
  STATUS,
  StoreHelpers,
} from "react-joyride";

import CalcTable from "../CalcTable";
import httpClient from "../auth/api/HTTPClient";

configure({ axios: httpClient });

const TableView = () => {
  const [{ currentYear }] = useContext(StateContext);

  const [queryParams] = useQueryParams({
    indexOption: StringParam,
    yob: StringParam,
    indexYear: StringParam,
    valueIndex: StringParam,
    valueIncome: StringParam,
    valueSsStart: StringParam,
    yobIndex: StringParam,
  });

  const pathToFile: string = `/index/get_details?yob=${queryParams.yob}&index_year=${queryParams.indexYear}&value_index=${queryParams.valueIndex}&data_year=${currentYear}`;

  const [calcTableData, setCalcTableData] = useState();

  // Get data Summary data for chosen year
  const [{ data, loading, error }] = useAxios(pathToFile);

  useEffect(() => {
    try {
      if (data && Object.keys(data.data).length > 0) {
        setCalcTableData(data.data);
      }
    } catch (err) {
      console.log("table view ERROR ", err);
    }
  }, [data]);

  // Responsive
  const [viewWidth, setViewWidth] = useState(0);
  const indexTextAlign =
    viewWidth >= Responsive.onlyComputer.minWidth! ? "right" : "left";
  const lgThanTablet =
    viewWidth >= Responsive.onlyTablet.minWidth! ? true : false;
  const handleOnUpdate = (e: any, viewWidth: any) => {
    setViewWidth(viewWidth.width);
  };

  const [joyrideHelpers, setJoyrideHelpers] = useState<StoreHelpers | any>();

  // React Joyride Steps
  const [joyrideState, setJoyrideState] = useState({
    run: false,
    stepIndex: 0,
    steps: [
      {
        target: ".minimum-joyride",
        placement: "top" as const,
        title: "Step 1",
        content:
          "For each year, the lessor amount between Hypothetical Earnings and Benefit Base is selected as the Earnings/Base Minimum.",
        disableBeacon: true,
      },
      {
        target: ".earnings-index-factor-joyride",
        placement: "top" as const,
        title: "Step 2",
        content:
          "Earnings Indexing Factors for each year, or attained age, are determined using historical National Average Wage Index (NAWI) values. For instance, the Earnings Indexing Factor for age 50 is found by dividing the age 60 NAWI value by the age 50 NAWI value.",
        disableBeacon: true,
      },
      {
        target: "body",
        placement: "center" as const,
        title: "Step 3",
        content:
          "The Indexed Earnings for a given age equals the Earnings/Base Minimum multiplied by the Earnings Indexing Factor at the same age. For instance, the age 50 Indexed Earnings equals the age 50 Earnings/Base Minimum multiplied by the age 50 Earnings Indexing Factor.",
        disableBeacon: true,
      },
      {
        target: ".highest-earnings-joyride",
        placement: "top" as const,
        title: "Step 4",
        content: "Determine the Highest 35 Indexed Earnings values.",
        disableBeacon: true,
      },
      {
        target: ".aime-joyride",
        placement: "top" as const,
        title: "Step 5",
        content:
          "Calculate the Average Indexed Monthly Earnings (AIME) by summing the highest 35 Indexed Earnings value and dividing by 420 which gives an AIME. This value is rounded down to the nearest dollar.",
        disableBeacon: true,
      },
      {
        target: ".pia-joyride",
        placement: "top" as const,
        title: "Step 6",
        content:
          "The Primary Insurance Amount (PIA) is determined using the prescribed Bend Points.",
        disableBeacon: true,
      },
      {
        target: ".mba-joyride",
        placement: "top" as const,
        title: "Step 7",
        content:
          "Determine the Monthly Benefit Amount (MBA) by applying Cost Of Living Adjustments (COLA) to the PIA, for years after attained age 62, and then apply the Early/Delayed Benefit Factor.",
        disableBeacon: true,
      },
      {
        target: ".aba-joyride",
        placement: "top" as const,
        title: "Step 8",
        content:
          "Finally, determine the Annual Benefit Amount (ABA) by multiplying the Monthly Benefit Amount by 12.",
        disableBeacon: true,
      },
    ],
  });

  // Start Joyride
  const handleStartJoyride = (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    event.preventDefault();
    setJoyrideState({ ...joyrideState, run: true });
  };

  // Get and set Joyride Helpers
  function getHelpers(helpers: StoreHelpers) {
    setJoyrideHelpers(helpers);
  }

  // Reset Joyride when done/closed
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;
    const stepEvents: string[] = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const closedActions: string[] = [ACTIONS.CLOSE];

    if (finishedStatuses.includes(status) || closedActions.includes(action)) {
      // Need to set our running state to false, so we can restart if we click start again.
      setJoyrideState({ ...joyrideState, run: false, stepIndex: 0 });
      joyrideHelpers.reset(true);
    } else if (stepEvents.includes(type)) {
      // Update state to advance the tour
      setJoyrideState({
        ...joyrideState,
        stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
      });
    }
  };

  if (
    error ||
    !queryParams.yob ||
    !queryParams.indexYear ||
    !queryParams.valueIndex
  ) {
    return <Redirect to={{ pathname: "/" }} />;
  }
  return (
    <>
      <Joyride
        run={joyrideState.run}
        steps={joyrideState.steps}
        stepIndex={joyrideState.stepIndex}
        continuous={true}
        scrollToFirstStep={false}
        showProgress={true}
        getHelpers={getHelpers}
        locale={{
          back: "Back",
          close: "Close",
          last: "Done",
          next: "Next",
          skip: "Skip",
        }}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            // arrowColor: '#fff',
            // backgroundColor: '#fff',
            // beaconSize: 36,
            // overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: "#2185d0",
            // spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            // textColor: '#333',
            // width: undefined,
            // zIndex: 100,
          },
          tooltipContent: {
            textAlign: "left",
          },
        }}
      />
      <section className="bi-m-section-top">
        {loading && <Loader size="big" active />}
        {!loading && data && (
          <Responsive
            as={Segment}
            fireOnMount
            onUpdate={handleOnUpdate}
            raised
            {...(lgThanTablet && { padded: true })}
          >
            <Header
              as="h2"
              size={lgThanTablet ? "large" : "medium"}
              style={{ marginBottom: "4rem" }}
            >
              <Grid columns={2}>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  Calculation of Value for Birth Year
                  {" " + queryParams.yob},{" " + queryParams.valueIncome}{" "}
                  Income, Social Security Starting at Age{" "}
                  {queryParams.valueSsStart} for Index Year{" "}
                  {queryParams.indexYear}
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={8}
                  computer={8}
                  textAlign={indexTextAlign}
                >
                  <Header sub>
                    <em>
                      {queryParams.indexOption ? queryParams.indexOption : ""}
                    </em>
                    <a
                      style={{ display: "block", marginTop: "0.5rem" }}
                      href="#!"
                      onClick={handleStartJoyride}
                    >
                      How is this calculated?
                    </a>
                  </Header>
                </Grid.Column>
              </Grid>
            </Header>
            {calcTableData && <CalcTable tableData={calcTableData} />}
          </Responsive>
        )}
      </section>
    </>
  );
};

export default TableView;
