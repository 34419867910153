import React, { useState, useRef, useContext } from "react";
import {
  Button,
  Form,
  Responsive,
  Segment,
  Input,
  Message,
  MessageContent
} from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";
import { StateContext } from "../state";

import httpClient from "./api/HTTPClient";
import Endpoint from "./api/Endpoint";

interface IRegisterInput {
  name: string;
  username: string;
  password: string;
  verifyPassword: string;
}

interface ErrorState {
  field: "name" | "username" | "password" | "verify-password";
  content: string;
  pointing: string;
}

interface RegisterState {
  isBusy: boolean;
  error: {
    message: string;
  };
}

const Register = () => {
  const [{ carrierProduct }] = useContext(StateContext);
  const history = useHistory();
  const authState = useRef<IRegisterInput>({
    name: "",
    username: "",
    password: "",
    verifyPassword: ""
  }).current;
  const [error, setError] = useState<ErrorState>();
  const [registerState, setRegisterState] = useState<RegisterState>({
    isBusy: false,
    error: {
      message: ""
    }
  });
  const { isBusy, error: registerError } = registerState;

  const handleSubmit = async () => {
    const { name, username, password, verifyPassword } = authState;
    if (!name || !name.trim().length) {
      setError({
        field: "name",
        content: "You must enter your full name.",
        pointing: "below"
      });
      return;
    }
    if (!username || !username.trim().length) {
      setError({
        field: "username",
        content: "You must enter your email address",
        pointing: "below"
      });
      return;
    }
    if (!password || password.trim().length < 6) {
      setError({
        field: "password",
        content: "Password must have at lease six characters.",
        pointing: "below"
      });
      return;
    }
    if (password !== verifyPassword) {
      setError({
        field: "verify-password",
        content: "Both passwords must be same.",
        pointing: "below"
      });
      return;
    }
    setError(undefined);
    try {
      setRegisterState({ isBusy: true, error: { message: "" } });
      const { data, status } = await httpClient.post(
        Endpoint.Register,
        authState
      );
      if (status !== 200 || !data.success) {
        throw new Error(data.message || "Failed to register the account");
      }
      history.push(carrierProduct.pathPrefix + "auth/verify", authState);
    } catch (exp) {
      setRegisterState({
        isBusy: false,
        error: { message: typeof exp === "string" ? exp : exp.message }
      });
    }
  };

  return (
    <>
      {carrierProduct && (
        <div style={{ maxWidth: 500, margin: "16px auto" }}>
          <Segment.Group size="massive" raised>
            <Responsive as={Segment}>
              <Form onSubmit={handleSubmit}>
                <Form.Field
                  control={Input}
                  label="Full name"
                  error={error && error.field === "name" ? error : undefined}
                  type="text"
                  icon="user"
                  iconPosition="left"
                  placeholder="Full name"
                  onChange={(event: any) =>
                    (authState.name = event.target.value)
                  }
                />
                <Form.Field
                  control={Input}
                  label="Email"
                  error={
                    error && error.field === "username" ? error : undefined
                  }
                  type="email"
                  icon="envelope"
                  iconPosition="left"
                  placeholder="Email"
                  onChange={(event: any) =>
                    (authState.username = event.target.value)
                  }
                />
                <Form.Field
                  control={Input}
                  label="Password"
                  error={
                    error && error.field === "password" ? error : undefined
                  }
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  onChange={(event: any) =>
                    (authState.password = event.target.value)
                  }
                />
                <Form.Field
                  control={Input}
                  label="Verify password"
                  error={
                    error && error.field === "verify-password"
                      ? error
                      : undefined
                  }
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder="Repeat password"
                  onChange={(event: any) =>
                    (authState.verifyPassword = event.target.value)
                  }
                />
                {!!registerError.message && (
                  <Message negative>
                    <MessageContent>{registerError.message}</MessageContent>
                  </Message>
                )}
                <Button
                  loading={isBusy}
                  disabled={isBusy}
                  type="submit"
                  primary
                  fluid
                >
                  {isBusy ? "Registering..." : "Register"}
                </Button>
                <p>
                  Already have an account?{" "}
                  <Link to={carrierProduct.pathPrefix + "auth/login"}>
                    Login
                  </Link>
                </p>
              </Form>
            </Responsive>
          </Segment.Group>
        </div>
      )}
      ;
    </>
  );
};

export default Register;
