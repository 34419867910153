import React, {useEffect} from "react";
import { Button, Modal } from "semantic-ui-react";
import BenefitFaq from "./BenefitFaq";
import ReactGA from 'react-ga';

const FaqModal = (props: any) => {

  useEffect(() => {
    if(props.show){
      ReactGA.modalview('/faq-modal');
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.show]);

  return (
    <>
      <Modal
        dimmer="blurring"
        open={props.show}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={props.handleModalClose}
      >
        <Modal.Header>Frequently Asked Questions</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <BenefitFaq />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Close" onClick={props.handleModalClose} />
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default FaqModal;
